.uninstall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f6f8fc;
  z-index: 1;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #1e1e1e;
    @include userSelectDisable;

    &-logo {
      cursor: default;
    }

    &-button {
      padding: 3px 8px;
      border-radius: 4px;
      color: #4d4558;
      background-color: #ffffff;
      font-family: 'Nexa Bold';
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        color: darken($color: #4d4558, $amount: 10);
        background-color: #f0f0f0;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 173px;

    &__restore {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 8px rgba(36, 45, 81, 0.2);
      border-radius: 12px;
      color: #4d4558;
      background-color: #ffffff;
      padding: 80px 160px;

      &__bold-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Nexa Bold';
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center;
        cursor: default;

        &-logo {
          margin-right: 12px;
          cursor: default;
        }
      }

      &__link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 32px;
        cursor: pointer;
        transition: color 0.1s linear;
        font-family: 'Nexa Book';
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0px;

        &-icon {
          margin-left: 12px;
          transition: all 0.1s linear;
        }

        &:hover {
          color: #000000;

          .uninstall__body__restore__link-icon {
            filter: brightness(0.5);
          }
        }
      }

      &__action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        font-family: 'Nexa Book';
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
        cursor: default;

        &-link {
          margin-left: 4px;
          color: #2f86c7;
          transition: color 0.05s linear;
          cursor: pointer;

          &:hover {
            color: #2971a8;
            text-decoration: underline;
          }
        }
      }
    }

    &__message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 76px;
      cursor: default;

      &-text-img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        @include userSelectDisable;
      }

      &__user {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-top: 32px;

        &-avatar {
          min-width: 50px;
          width: 50px;
          height: 50px;
          max-height: 50px;
          border-radius: 50%;
        }

        &-custom-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 50px;
          width: 50px;
          height: 50px;
          max-height: 50px;
          transition: all 0.7s;
          border-radius: 50%;
          color: #4d4558;
          background-color: #edf0f4;
          font-family: 'Nexa Bold', sans-serif;
          font-size: 20px;
          outline: 1px solid #e2e2e2;
        }

        &__info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-left: 12px;

          &-name {
            font-family: 'Nexa Bold';
            font-size: 20px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0px;
            text-align: left;
          }

          &-title {
            font-family: 'Nexa Regular';
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: #8189a1;
          }
        }
      }
    }
  }
}
