@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import './styles/fonts/fonts';
@import './styles/variables';
@import './components/components';
@import './pages/pages';

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Open Sans', sans-serif;
}

.page {
  height: 100vh;
  display: flex;
  overflow-y: auto;
  color: $blue-primary;
  background: url(./assets/page-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-logo {
      width: 40%;
      margin-top: 6rem;
    }

    &-version {
      color: #aaa;
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 14px;
    }
  }
}

.slider {
  width: 100%;
  height: 100%;
}

.slider-enter {
  transform: translateX(100%);
}

.slider-enter-active {
  transform: translateX(0);
  transition: transform 0.4s;
}

.slider-exit {
  transform: translateX(0);
}

.slider-exit-active {
  transform: translateX(-100%);
  transition: transform 0.4s;
}

::-webkit-scrollbar {
  width: 6px;
}

::-moz-scrollbar {
  width: 6px;
}

::-ms-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

::-moz-scrollbar-track {
  background: #ffffff;
}

::-ms-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e2ee;
  border-radius: 4px;
}

::-moz-scrollbar-thumb {
  background: #e0e2ee;
  border-radius: 4px;
}

::-ms-scrollbar-thumb {
  background: #e0e2ee;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb;
}

::-moz-scrollbar-thumb:hover {
  background: #dbdbdb;
}

::-ms-scrollbar-thumb:hover {
  background: #dbdbdb;
}
