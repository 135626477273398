.signup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  background-color: white;
  border-radius: 12px;
  color: #4d4558;
  min-height: 100%;
  border-radius: 12px;

  &__left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 63.5%;
    max-width: 63.5%;
    background-color: #f8f8fb;
    padding: 15px 30px;
    border-radius: 4px 0px 0px 4px;
    background: url(../../assets/google-bg-1920.png);
    background-size: cover;

    @media screen and (max-width: 1330px) {
      padding: 15px 10px 30px;
    }

    @media screen and (max-width: 900px) {
      min-width: 100%;
      max-width: 100%;
    }

    &__link-back {
      position: absolute;
      top: 20px;
      left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #cdd5e5;
      font-family: 'Nexa Regular';
      font-size: 14px;
      line-height: 21px;
      transition: all 0.2s;

      &-chevron {
        margin-right: 2px;
        transition: all 0.2s;
      }

      &:hover {
        filter: brightness(1.1);
      }
    }

    &-title {
      font-family: 'Nexa Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-top: 25px;
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 300px;

      &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'Nexa Book';
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;

        &::before {
          content: url(./assets/checkmark.svg);
          width: 24px;
          height: 24px;
          margin: 0px 10px;
        }

        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      &__google {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        font-family: 'Nexa Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;

        &__contact {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 8px;

          &-text {
            color: #ffffff;
            cursor: default;
          }

          &-link {
            color: #ffffff;
            margin-left: 4px;
            text-decoration: underline;
            cursor: pointer;
            transition: all 0.1s;
            @include userSelectDisable;

            &:hover {
              color: #f0f0f0;
            }
          }
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: left;
      color: #8189a1;
      @include userSelectDisable;

      &__text {
        font-family: 'Nexa Regular';
        font-size: 10px;
        font-weight: 400;
        line-height: 15.6px;
        letter-spacing: 0em;
        cursor: default;
        text-align: center;
        color: #ffffff;

        &-link {
          font-family: 'Nexa Bold';
          font-size: 10px;
          font-weight: 700;
          line-height: 15.6px;
          letter-spacing: 0em;
          display: inline-block;
          text-decoration: underline;
          cursor: pointer;
          transition: all 0.2s;
          color: #ffffff;
          @include userSelectDisable;

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 36.6%;
    width: 36.6%;
    max-width: 400px;
    background: url(../../assets/self-sidebar-bg-1920.png);
    border-radius: 0 4px 4px 0;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1330px) {
      padding: 15px 15px 10px 0px;
    }

    &-title {
      font-family: 'Nexa Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-top: 25px;
    }

    &__content {
      margin-top: 60px;
      width: 100%;

      &__notice {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 20px;

        &__row {
          display: flex;
          flex-direction: row;
          position: relative;

          &:first-child {
            &::after {
              content: '';
              display: block;
              height: 31px;
              width: 1px;
              position: absolute;
              background-color: #ffcc39;
              transform: translate(34px, 49px);
            }
          }

          &:not(:first-child) {
            margin-top: 33px;

            &::after {
              content: '';
              display: block;
              height: 31px;
              width: 1px;
              position: absolute;
              background-color: #edf0f4;
              transform: translate(34px, 49px);
            }
          }

          &:last-child {
            &::after {
              content: '';
              display: none;
            }
          }

          &-img {
            position: relative;
            padding: 0px 15px;
            cursor: default;
          }

          &__info {
            display: flex;
            flex-direction: column;

            &-title {
              font-family: 'Nexa Bold';
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }

            &-explanation {
              font-family: 'Nexa Regular';
              font-size: 12px;
              font-weight: 300;
              line-height: 24px;
            }
          }
        }
      }

      &-divider {
        width: 83%;
        height: 1px;
        background-color: #edf0f4;
        margin-top: 30px;
        margin-bottom: 15px;
      }

      &__message {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        &-img {
          @include userSelectDisable;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    &__left-section {
      min-width: 100%;
      max-width: 100%;

      &__link-back {
        left: 16px;
        top: 34px;
      }

      &__footer__text {
        padding: 0 20px;
      }
    }

    &__right-section {
      display: none;
    }
  }
}
