.button {
  position: relative;
  border-radius: 4px;
  border: 0;
  padding: 1.24rem;
  width: 100%;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;

  &__children-text {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  &:disabled {
    background: #dbdbdb;
    color: white;
    filter: brightness(0.8);
    opacity: 0.8;
    pointer-events: none;
    @include userSelectDisable;
  }

  &-google {
    display: flex;
    align-items: center;
    align-self: center;
    color: $blue-secondary;
    background-color: #4285f4;
    padding: 0px;
    width: 220px;
    min-height: 40px;
    border-radius: 2px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084),
      0px 1px 1px rgba(0, 0, 0, 0.168);

    &:hover {
      background-color: #3367d6;
    }

    &.light {
      background-color: #ffffff;
      // transition: all 0.2s;
      transition: none;

      &:hover {
        filter: brightness(0.95);
      }
    }

    &__icon {
      border-radius: 1px;
      margin: 1px;
      width: 38px;
      height: 38px;
    }

    &__text {
      display: block;
      font-family: 'Roboto', sans-serif;
      width: 161px;
      margin: 0px 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      color: white;

      &.dark-text {
        margin: 0;
        color: #4d4558;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }
  }

  &-ordinary {
    &-filled {
      color: #fff;
      background-color: $blue-secondary;
    }

    &-text {
      color: $blue-secondary;
      background-color: #fff;
    }
  }
}
