.error-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #303030;
    opacity: 0.95;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__window {
    cursor: default;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 1001;
    width: 600px;
    height: 252px;
    border-radius: 8px;
    padding: 30px 91px;

    &-close-icon {
      position: relative;
      top: -4px;
      left: 461px;
      cursor: pointer;
      transition: all 0.1s;

      &:hover {
        transform: scale(1.05);
        filter: brightness(0.7);
      }
    }

    &__title {
      display: flex;
      align-items: center;
      font-family: 'Nexa Bold';
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 27px;

      &-icon {
        margin: 0px 18px 0px -50px;
      }
    }

    &__content {
      font-family: 'Nexa Book';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;

      &-first-word {
        margin-left: 4px;
      }

      &-link {
        text-decoration: none;
        color: #0a45d7;
        cursor: pointer;
        transition: color 0.1s;

        &:hover {
          color: darken($color: #0a45d7, $amount: 4);
        }
      }
    }
  }
}
