.processing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  max-width: 100%;
  background-color: white;
  border-radius: 12px;
  color: #4d4558;
  height: 100%;
  border-radius: 12px;

  &__left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    max-width: 70%;
    background-color: #ffffff;
    padding: 15px 30px;
    border-radius: 4px 0px 0px 4px;

    &-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &::before {
        content: url(./assets/processing-loader.png);
        width: 40px;
        height: 40px;
        animation: infiniteRotation 2s linear infinite;
      }

      &::after {
        content: 'Processing...';
        width: max-content;
        height: max-content;
        margin-top: 10px;
        color: #4d4558;
        text-align: center;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      @keyframes infiniteRotation {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &__error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      animation: fadeInContent 0.5s linear;
      width: 80%;

      &-img {
        min-height: 36px;
        min-width: 36px;
        cursor: default;
        @include userDragDisable;
        animation: scaleIn 0.3s linear;

        @keyframes scaleIn {
          0% {
            transform: scale(0);
          }

          70% {
            transform: scale(1.3);
          }

          100% {
            transform: scale(1);
          }
        }
      }

      &-message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-width: 75%;
        width: 75%;
        max-width: 80%;
        margin-top: 16px;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #4d4558;
      }

      &-text {
        min-width: 233px;
        margin-top: 3px;
        color: #8189a1;
        text-align: center;
        font-family: 'Nexa Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include userSelectDisable;
      }

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        margin-top: 16px;
        padding: 6px 12px;
        border-radius: 4px;
        background: #4d4558;
        color: #ffffff;
        font-family: 'Nexa Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.1s linear;
        @include userSelectDisable;

        &:hover {
          filter: brightness(1.15);
        }
      }
    }

    @media screen and (max-width: 1330px) {
      padding: 15px 10px 30px;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;
    width: 30%;
    max-width: 400px;
    background: url(../../assets/self-sidebar-bg-1920.png);
    background-size: cover;
    background-position: center;
    border-radius: 0 4px 4px 0;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
