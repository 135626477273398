@font-face {
  font-family: 'Nexa Bold';
  src: url(./Nexa-Bold.ttf);
}

@font-face {
  font-family: 'Nexa Light';
  src: url('./Nexa-Light.ttf');
}

@font-face {
  font-family: 'Nexa Regular';
  src: url('./NexaRegular.ttf');
}

@font-face {
  font-family: 'Nexa Thin';
  src: url('./NexaThin.ttf');
}

@font-face {
  font-family: 'Nexa Heavy';
  src: url('./NexaHeavy.ttf');
}

@font-face {
  font-family: 'Nexa XBold';
  src: url('./Nexa-XBold.ttf');
}

@font-face {
  font-family: 'Nexa Book';
  src: url('./Nexa-Book.ttf');
}
