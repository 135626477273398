$border-color: rgb(160, 160, 161);
$text-color: rgb(2, 1, 37);
$background-color: #ffffff;
$background-color-hover: rgb(232, 232, 232);

.mainBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 125px;
  width: 100%;
  z-index: 100;
  min-height: 35px;
  cursor: pointer;
  font-family: 'Nexa Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  animation: fadeIn 0.4s linear;

  &.up-index {
    z-index: 101;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  &__label {
    text-align: start;
    text-transform: capitalize;
    display: block;
    font-family: 'Nexa XBold';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    margin: 0px 0px 8px 0px;
    color: rgb(69, 69, 69);

    &.required {
      &::after {
        content: '*';
        width: 2px;
        height: 2px;
        color: red;
      }
    }
  }

  &-current-selected-item-container {
    padding: 1px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: $background-color;
    z-index: 2;
    border-radius: 4px;
    outline: 1px solid #e0e2ee;
    padding: 0px 10px;

    &.open {
      outline: 1px solid $border-color;
    }

    &.warn {
      outline: 0.1px solid #eba53a;
    }

    &.error {
      outline: 0.1px solid red;
    }

    &-optionalIcon {
      width: 24px;
      height: 24px;
      @include userSelectDisable;
    }
  }

  &-current-selected-item-text {
    font-family: 'Nexa Regular';
    font-size: 16px;
    font-weight: 400;
    color: #4d4558;
    min-width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include userSelectDisable;
  }

  &-chevron-container {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateX(180deg);
    transition: all 0.1s linear;

    &.revert {
      transform: rotateX(360deg);
    }
  }

  &-optionalIcon {
    position: absolute;
    left: 5%;
  }

  //drop

  &-dropped-overflow-container {
    overflow-y: auto;
    overflow-x: visible;
    height: fit-content;
    width: 100%;
    top: 10px;
    position: absolute;
    cursor: pointer;
    filter: drop-shadow(0px 2px 8px rgba(36, 45, 81, 0.2));
    border-radius: 4px;

    &.closed {
      animation: closeDropdownAnimation 0.2s linear;
      width: 0;
      height: 0;
    }
  }

  &-dropped-block {
    max-height: 180px;
    background-color: $background-color;
    padding-top: 25px;
    padding-bottom: 5px;
    z-index: 1;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    border-radius: 10px;
    border-top: none;

    &.closed {
      transform: translateY(-100%);
      padding-top: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @keyframes closeDropdownAnimation {
      0% {
        height: 100%;
        opacity: 1;
      }
      100% {
        height: 0px;
        opacity: 0;
      }
    }
  }

  .drop-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 0px 5px 11px;
    min-height: 30px;
    font-size: 12px;
    white-space: nowrap;
    font-family: 'Nexa Regular';
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background: #edf0f4;
      color: #646c83;
      // border-radius: 10px;
    }

    &.disabled {
      color: rgb(207, 207, 207);
      pointer-events: none;
      cursor: default;
    }
  }
}
