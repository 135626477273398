.features {
  position: relative;
  width: 100%;
  height: max-content;
  margin-top: 30px;
  @include userSelectDisable;
  cursor: default;
  max-height: unset;
  // transition: all 0.5s linear;

  &.hidden {
    height: 0;
    max-height: 0px;
    margin-top: 0px;
    opacity: 0;
    overflow: hidden;
    // transition: all 0.5s linear;
  }

  &-scroll-el {
    transform: translateY(-80px);
    width: 0px;
    height: 0px;
    z-index: -1;
  }

  &-top-scroll-el {
    transform: translateY(-800px);
    width: 0px;
    height: 0px;
    z-index: -1;
  }

  &__table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: #4d4558;
    min-height: 10px;

    &__title-row {
      display: flex;
      flex-direction: row;
      justify-content: unset;
      align-items: center;
      width: 100%;
      height: 120px;
      background-color: #f7f8fa;
      border-bottom: 1px solid #e0e7f0;
      padding: 20px 0px 20px 20px;
      border-radius: 4px 4px 0 0;

      &__cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 20%;
        color: #4d4558;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &:first-child {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 40%;
          font-family: 'Nexa XBold';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }
    }

    &__feature-row {
      display: flex;
      flex-direction: row;
      justify-content: unset;
      align-items: center;
      width: 100%;
      height: 59px;
      background-color: #ffffff;
      border-bottom: 1px solid #e0e7f0;
      padding-left: 20px;

      &:last-child {
        height: 60px;
        border-bottom: none;
        border-radius: 0 0 4px 4px;
        margin-bottom: 10px;
      }

      &-name {
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        width: 40%;
      }

      &-checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 1660px) and (max-width: 1919px) {
    margin-top: 15px;

    &-scroll-el {
      transform: translateY(-49px);
    }

    &__table__title-row {
      height: 110px;
      padding: 15px;
    }
    &__table__feature-row {
      height: 50px;
      padding: 15px;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1659px) {
    margin-top: 15px;

    &-scroll-el {
      transform: translateY(-47px);
    }

    &__table__title-row {
      height: 110px;
      padding: 15px;
    }
    &__table__feature-row {
      height: 50px;
      padding: 15px;
    }
  }

  @media screen and (max-width: 1439px) {
    margin-top: 15px;

    &-scroll-el {
      transform: translateY(-47px);
    }

    &__table__title-row {
      height: 110px;
      padding: 15px;
    }
    &__table__feature-row {
      height: 50px;
      padding: 15px;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 0px 10px 5px 19px;

    &__table__plan-mv {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-title {
        background-color: #f4f7ff;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px 40px;
      }

      &-subtitle {
        background-color: #ffffff;
        color: #1e293b;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding: 6px 40px;
      }

      &-feature {
        background-color: #ffffff;
        color: #1e293b;
        font-family: 'Nexa Book';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 6px 40px;
        border-bottom: 1px solid #edf0f4;
      }
    }

    &-scroll-el {
      transform: translateY(-77px);
      width: 0px;
      height: 0px;
      z-index: -1;
    }
  }
}
