$toggle-bg-active: #32b76c;
$toggle-border-active: #ffffff;

$toggle-bg-inactive: transparent;
$toggle-border-inactive: #ffffff;

.toggle {
  position: relative;
  display: block;
  background-color: $toggle-bg-inactive;
  color: #646c83;
  border-radius: 36.5px;
  min-width: 40px;
  width: 40px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid $toggle-border-inactive;
  transition: all 0.3s ease;

  &-active {
    background-color: $toggle-bg-active;
    border: 1px solid $toggle-border-active;
  }

  &.dark {
    background-color: #1e1e1e;
    transition: color 0s;
  }

  &__toggle {
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translate(0, -50%);
    background-color: #fff;
    // border: 0.5px solid #cdd5e5;
    box-shadow: 0px 1px 2px rgba(85, 85, 85, 0.38);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transition: all 0.3s ease;
  }

  &__control {
    width: 0px;
    border: 0;

    &:checked + .toggle__toggle {
      left: 100%;
      transform: translate(calc(-100% - 1px), -50%);
      box-shadow: 0px 1px 2px rgba(15, 71, 40, 0.38);
      // opacity: 0.6;
    }
  }

  &__label {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(3px, -45%);
    transition: all 0.3s ease;

    &-active {
      color: $toggle-border-active;
    }

    &-inactive {
      color: #8189a1;
      transform: translate(80%, -45%);
    }
  }
}
