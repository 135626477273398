.self-signup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  width: 100%;
  max-width: 100vw;
  z-index: 5;
  overflow: hidden;

  &-background-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100vh;
    height: 100.5%;
    min-width: 100vw;
    width: 100.5%;
    background: url(./assets/1280-s-bg.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    z-index: -1;
    overflow: hidden;
    filter: brightness(0.65);
  }

  &.no-x-overflow {
    overflow-x: hidden;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 80%;
    max-width: 1180px;
    margin-top: 36px;
    @include userSelectDisable;

    &-img {
      width: 156px;
      height: 24px;
    }
  }

  &__content {
    display: flex;
    width: 1110px;
    min-width: 1110px;
    max-width: 1110px;
    min-height: 820px;
    filter: drop-shadow(0px 2px 8px rgba(36, 45, 81, 0.2));
    border-radius: 12px;

    .signup,
    .order,
    .pricing,
    .payment-details,
    .processing,
    .ss-add-extension {
      animation: fadeInContent 0.5s ease-in;
    }

    @keyframes fadeInContent {
      0% {
        opacity: 0.5;
      }

      80% {
        opacity: 0.9;
      }

      100% {
        opacity: 1;
      }
    }

    &.co-page {
      position: absolute;
      height: 70vh;
      min-width: 1009px;
      margin-top: 0px;
      top: 0px;
      left: 50%;
      transform: translate(-50%, 120px);
      filter: none;
      z-index: 2;
    }
  }

  @media screen and (min-width: 1920px) {
    &-background-img {
      background: url(./assets/1920-s-bg.png);
    }
  }

  @media screen and (min-width: 1660px) and (max-width: 1919px) {
    &-background-img {
      background: url(./assets/1660-s-bg.png);
    }

    &__content {
      min-width: 1062px;
      max-width: 1062px;
      min-height: 720px;
      max-height: 720px;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1659px) {
    &-background-img {
      background: url(./assets/1440-s-bg.png);
    }

    &__content {
      min-width: 980px;
      max-width: 980px;
      min-height: 640px;
      max-height: 640px;
    }
  }

  @media screen and (max-width: 1439px) {
    &-background-img {
      background: url(./assets/1280-s-bg.png);
    }

    &__content {
      min-width: 940px;
      max-width: 940px;
      min-height: 600px;
      max-height: 600px;
    }
  }

  @media screen and (max-width: 900px) {
    &-background-img {
      background: url(./assets/1280-s-bg.png);
    }

    &__content {
      min-width: 100vw;
      min-height: 100vh;
      width: 100%;
    }
  }
}
