@import './components/PlanCard/plan-card';
@import './components/FeaturesTable/features-table';

.pricing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  min-width: 100%;
  background-color: white;
  border-radius: 12px;
  color: #4d4558;
  height: 100%;
  border-radius: 4px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 124px;
    background: url(../../assets/pricing-header-1920.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px 4px 0 0;
    padding: 48px 40px;

    &__left-section {
      color: #ffffff;
      @include userSelectDisable;

      &-title {
        font-family: 'Nexa Bold';
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
      }
    }

    &__right-section {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      @include userSelectDisable;

      &__toggle-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 163px;
        font-family: 'Nexa Bold';
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;

        &-toggle {
          width: 40px;
          height: 20px;
        }
      }

      &-text {
        margin-top: 5px;
        font-family: 'Nexa XBold';
        font-size: 8px;
        font-weight: 800;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: right;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0px 10px 10px 10px;

    &-overflow {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: max-content;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 25px 0px 0px;

      &.no-scroll {
        &::-webkit-scrollbar {
          width: 0px;
        }
      }

      // &::-webkit-scrollbar {
      //   width: 1px;
      // }

      // &::-moz-scrollbar {
      //   width: 0px;
      // }

      // &::-ms-scrollbar {
      //   width: 0px;
      // }

      &::-webkit-scrollbar-thumb {
        background: #cdd5e5;
        border-radius: 4px;
        box-shadow: inset 0px 25px 0px 0px #ffffff;
      }
      &::-moz-scrollbar-thumb {
        background: #cdd5e5;
        border-radius: 4px;
        box-shadow: inset 0px 25px 0px 0px #ffffff;
      }
      &::-ms-scrollbar-thumb {
        background: #cdd5e5;
        border-radius: 4px;
        box-shadow: inset 0px 25px 0px 0px #ffffff;
      }
    }

    &-ref-el {
      width: 0px;
      height: 0px;
      transform: translateY(-25px);
      z-index: -1;
    }

    &__choose-plan {
      width: 100%;
      cursor: default;
      // margin-bottom: 20px;
      padding: 0px 13px;

      &-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border-radius: 12px;
        border: 1px solid #edf0f4;
      }
    }

    &__features {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding: 20px 13px 10px;

      &-label {
        position: relative;
        display: block;
        color: #4d4558;
        width: max-content;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        transition: all 0.1s linear;
        @include userSelectDisable;

        &:hover {
          filter: brightness(1.5);
        }

        &::after {
          content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMjkyODkgOC4yOTI4OUM0LjY4MzQyIDcuOTAyMzcgNS4zMTY1OCA3LjkwMjM3IDUuNzA3MTEgOC4yOTI4OUwxMiAxNC41ODU4TDE4LjI5MjkgOC4yOTI4OUMxOC42ODM0IDcuOTAyMzcgMTkuMzE2NiA3LjkwMjM3IDE5LjcwNzEgOC4yOTI4OUMyMC4wOTc2IDguNjgzNDIgMjAuMDk3NiA5LjMxNjU4IDE5LjcwNzEgOS43MDcxMUwxMi43MDcxIDE2LjcwNzFDMTIuMzE2NiAxNy4wOTc2IDExLjY4MzQgMTcuMDk3NiAxMS4yOTI5IDE2LjcwNzFMNC4yOTI4OSA5LjcwNzExQzMuOTAyMzcgOS4zMTY1OCAzLjkwMjM3IDguNjgzNDIgNC4yOTI4OSA4LjI5Mjg5WiIgZmlsbD0iIzRENDU1OCIvPgo8L3N2Zz4K');
          position: absolute;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          transform: translate(3px, 0px) rotateX(360deg);

          font-size: 23px;
          padding-left: 5px;
          transition: all 0.05s linear;
        }

        &.active {
          &::after {
            transform: translate(2px, 0px) rotateX(180deg);
          }
        }
      }

      &-table {
        // margin-top: 30px;
        width: 100%;
        background-color: #ffffff;
      }
    }
  }

  @media screen and (min-width: 1660px) and (max-width: 1919px) {
    &__header {
      height: 112px;
    }

    &__content {
      &__features {
        padding: 12px 13px 0px;
      }
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1659px) {
    &__header {
      height: 98px;
    }

    &__content {
      &-overflow {
        padding-top: 20px;
        min-height: 101%;
      }

      &__choose-plan {
        padding: 0px 10px;
      }

      &__features {
        padding: 7px 13px 0px;
      }
    }
  }

  @media screen and (max-width: 1439px) {
    height: 100%;

    &__header {
      height: 85px;
      padding: 35px 25px;
    }

    &__content {
      &-overflow {
        padding-top: 20px;
        min-height: 101%;
      }

      &__choose-plan {
        padding: 0px 10px;
      }

      &__features {
        padding: 7px 13px 0px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: 100.5%;

    &__header {
      height: 116px;
      padding: 15px 25px;
      align-items: flex-end;
      // position: relative;

      &__left-section-title {
        font-size: 18px;
      }

      &__right-section {
        color: #1e1e1e;
        align-items: center;
        padding: 10px 0;
      }
    }

    &__content {
      padding: 0px;

      &-overflow {
        padding: 0px;

        &::-webkit-scrollbar {
          width: 0px;
        }
      }

      &__choose-plan {
        padding: 0;

        &-cards {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: max-content;
          min-width: 100%;
          background-color: white;
          border-radius: 12px;
          color: #4d4558;
          height: 100%;
          border-radius: 4px;
          border: none;
        }
      }

      &__features-label {
        padding: 0 10px 15px;
      }
    }
  }
}
