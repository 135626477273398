.plan {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;
  min-height: 500px;
  position: relative;
  padding: 40px 20px;
  color: #0b3857;

  &.recommended-p {
    background-color: #f5faff;
  }

  &.custom {
    min-height: 500px;
  }

  &-most-popular {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 22px;
    // transform: translateX(-50%);
    width: 116px;
    height: 33px;
    padding: 6px 12px 6px 12px;
    border-radius: 0px 0px 6px 6px;
    color: #ffffff;
    background-color: #29335c;
    font-family: 'Nexa Bold';
    font-size: 14px;
    font-weight: 800;
    line-height: 15px;
  }

  &-name {
    font-family: 'Nexa Bold';
    font-size: 28px;
    font-weight: 800;
    text-align: left;
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
    transform: scale(1);

    &.animation {
      animation: priceChangeAnimation 0.3s linear;
    }

    &-dollar {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-family: 'Nexa XBold';
      font-size: 24px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 3px;

      &.recommended-p {
        color: #2998fe;
      }
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-family: 'Nexa XBold';
      font-size: 64px;
      font-weight: 800;
      line-height: 59px;
      letter-spacing: 0px;
      text-align: left;
      margin: 0px 5px 0px 3px;

      &.recommended-p {
        color: #2998fe;
      }
    }

    &-text {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      white-space: pre-line;
      font-family: 'Nexa Book';
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0px;
      text-align: left;
      color: #8189a1;
    }

    @keyframes priceChangeAnimation {
      0% {
        transform: scaleX(0.9) scaleY(0.85);
        opacity: 0;
      }

      80% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &-description {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    white-space: pre-line;
    height: 48px;
    margin-top: 4px;
    font-family: 'Nexa Bold';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
  }

  &-subscribe-button {
    width: max-content;
    margin-top: 20px;
    display: flex;
    height: 40px;
    min-width: 140px;
    width: 70%;
    max-width: 180px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border: 2px solid #4d96f7;
    border-radius: 6px;
    color: #4d96f7;
    background-color: #ffffff;
    text-align: center;
    font-family: 'Nexa Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: darken($color: #ffffff, $amount: 4);
    }

    &.recommended-p {
      color: #ffffff;
      background: #4d96f7;
      border-color: #4d96f7;

      &:hover {
        background-color: darken($color: #4d96f7, $amount: 4);
      }
    }
  }

  &__select {
    height: 40px;
    min-width: 140px;
    width: 70%;
    max-width: 180px;
    margin-top: 20px;
  }

  &__features-info {
    margin-top: 20px;

    &-title {
      color: #4d96f7;
      font-family: 'Nexa Bold';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__features {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        margin-top: 15px;
        color: #0b3857;
        font-family: 'Nexa Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;

        &::before {
          content: url(./assets/feature-checkmark-icon.svg);
          margin-right: 6px;
          min-width: 22px;
          width: 22px;
          min-height: 22px;
          height: 22px;
        }
      }
    }
  }

  @media screen and (min-width: 1660px) and (max-width: 1919px) {
    padding: 36px 20px 20px;

    &-name {
      font-size: 24px;
      font-weight: 800;
      line-height: normal;
    }

    &__price {
      &-dollar {
        font-size: 20px;
        font-weight: 800;
        line-height: normal;
      }

      &-value {
        font-size: 52px;
        line-height: 59px;
        letter-spacing: -1px;
      }

      &-text {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
      }
    }

    &-description {
      margin-top: 3px;
      font-family: 'Nexa Bold';
      font-size: 16px;
      font-weight: 700;
    }

    &-subscribe-button {
      margin-top: 10px;
    }

    &__select {
      margin-top: 15px;
    }

    &__features-info {
      margin-top: 15px;

      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      &__features {
        &-row {
          margin-top: 12px;

          font-size: 14px;

          &::before {
            content: url(./assets/feature-checkmark-icon.svg);
            margin-right: 4px;
            min-width: 20px;
            width: 20px;
            min-height: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1659px) {
    padding: 32px 16px 6px;
    min-height: 460px;

    &-name {
      font-size: 20px;
      font-weight: 800;
      line-height: normal;
    }

    &__price {
      &-dollar {
        font-size: 16px;
        font-weight: 800;
        line-height: normal;
      }

      &-value {
        font-size: 44px;
        line-height: 39px;
        letter-spacing: -1px;
      }

      &-text {
        margin-top: 6px;
        font-size: 10px;
        font-weight: 300;
        line-height: normal;
      }
    }

    &-description {
      margin-top: 3px;
      font-family: 'Nexa Bold';
      font-size: 12px;
      font-weight: 700;
      height: 36px;
    }

    &-subscribe-button {
      margin-top: 12px;
    }

    &__select {
      margin-top: 12px;
    }

    &__features-info {
      margin-top: 12px;

      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      &__features {
        &-row {
          margin-top: 12px;
          font-size: 12px;

          &::before {
            content: url(./assets/feature-checkmark-icon.svg);
            margin-right: 7px;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    padding: 32px 16px 16px;
    min-height: 430px;

    &-most-popular {
      height: 26px;
      font-size: 12px;
    }

    &-name {
      font-size: 16px;
      font-weight: 800;
      line-height: normal;
    }

    &__price {
      &-dollar {
        font-size: 14px;
        font-weight: 800;
        line-height: normal;
      }

      &-value {
        font-size: 44px;
        line-height: 25px;
        letter-spacing: -1px;
      }

      &-text {
        margin-top: 4px;
        font-size: 10px;
        font-weight: 300;
        line-height: normal;
      }
    }

    &-description {
      margin-top: 3px;
      font-family: 'Nexa Bold';
      font-size: 12px;
      font-weight: 700;
      height: 36px;
    }

    &-subscribe-button {
      margin-top: 12px;
      min-width: 125px;
      max-width: 125px;
    }

    &__select {
      margin-top: 12px;
      min-width: 125px;
      max-width: 125px;
    }

    &__features-info {
      margin-top: 12px;

      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      &__features {
        &-row {
          margin-top: 12px;
          font-size: 12px;

          &::before {
            content: url(./assets/feature-checkmark-icon.svg);
            margin-right: 7px;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 470px;
    position: relative;
    padding: 35px 32px 20px;
    color: #0b3857;

    &.custom {
      min-height: 390px;
    }

    &__price-text {
      margin-top: 12px;
    }

    &-description {
      font-size: 16px;
    }
  }
}
