.payment-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 40px;
  padding: 6px 12px;
  border-radius: 4px;
  border: 0;
  color: #ffffff;
  background-color: #4d4558;
  font-family: 'Nexa XBold';
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.1s linear;

  &.disabled {
    color: #e0e2ee;
  }

  &.check {
    background-color: #32b76c;
  }

  &.error {
    background-color: #ce1331;
  }

  &:hover {
    filter: brightness(1.1);
  }

  img {
    animation: fadeInContent 0.2s linear;
  }

  @media screen and (max-width: 900px) {
    min-width: 80px;
  }
}
