.ss-add-extension {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  background-color: white;
  border-radius: 12px;
  color: #4d4558;
  height: 100%;
  border-radius: 12px;

  &__header {
    display: none;
  }

  &__left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 75%;
    background-color: #f8f8fb;
    padding: 80px 60px;
    border-radius: 4px 0px 0px 4px;

    &__link-back {
      position: absolute;
      top: 15px;
      left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #cdd5e5;
      font-family: 'Nexa Regular';
      font-size: 14px;
      line-height: 21px;
      transition: all 0.2s;

      &-chevron {
        margin-right: 2px;
        transition: all 0.2s;
      }

      &:hover {
        filter: brightness(1.1);
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      color: #4d4558;
      @include userSelectDisable;

      &-title {
        font-family: 'Nexa XBold';
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      &-subtitle {
        margin-top: 5px;
        display: block;
        white-space: pre-line;
        font-family: 'Nexa Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
      }
    }

    &__content {
      margin-top: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 5px;

      &-overflow {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 5px;
      }

      &__row {
        display: flex;
        flex-direction: row;
        position: relative;

        &-img {
          position: relative;
          padding: 0px 15px;
          cursor: default;
          align-self: baseline;
        }

        &__info {
          display: flex;
          flex-direction: column;
          color: #4d4558;
          height: max-content;

          &-title {
            font-family: 'Nexa Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          &-subtitle {
            margin-top: 3px;
            max-width: 445px;
            font-family: 'Nexa Regular';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #8189a1;
          }

          &-button {
            margin-top: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 180px;
            padding: 6px 12px;
            color: #ffffff;
            border-radius: 4px;
            background-color: #1a73e8;
            font-family: 'Nexa Bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
            cursor: pointer;
            transition: all 0.1s linear;

            &:hover {
              filter: brightness(0.95);
            }
          }

          &-link {
            margin-top: 6px;
            color: #8189a1;
            font-family: 'Nexa Regular';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.1s;

            &:hover {
              color: darken($color: #365798, $amount: 6);
            }
          }
        }

        &:first-child {
          height: 80px;

          &::after {
            content: '';
            position: absolute;
            display: block;
            height: 60px;
            width: 4px;
            border-radius: 6px;
            background-color: #32b76c;
            transform: translate(30.5px, 45px);
          }
        }

        &:nth-child(2) {
          margin-top: 34px;
          height: 100px;

          .ss-add-extension__right-section__content__notice__row__info {
            justify-content: flex-start;
          }

          &::after {
            content: '';
            display: block;
            height: 120px;
            width: 1px;
            border-left: 4px dotted #9aabcb;
            position: absolute;
            transform: translate(31px, 44px);
          }
        }

        &:nth-child(3) {
          margin-top: 70px;
          align-items: center;

          &::after {
            content: '';
            position: absolute;
            display: block;
            height: 55px;
            width: 1px;
            background-color: #edf0f4;
            transform: translate(34px, 52px);
          }
        }

        &:last-child {
          &::after {
            content: '';
            display: none;
          }
        }
      }

      &-divider {
        width: 83%;
        height: 1px;
        background-color: #edf0f4;
        margin-top: 30px;
        margin-bottom: 15px;
      }

      &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #365798;
        margin-left: 4px;
        margin-top: 58px;
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.1s;
        font-family: 'Nexa Regular';
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

        @include userSelectDisable;

        &::after {
          content: url(./assets/link-icon.svg);
          width: 20px;
          height: 20px;
          margin-left: 7px;
        }

        &:hover {
          color: darken($color: #365798, $amount: 6);

          &::after {
            filter: brightness(0.8);
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 400px;
      margin-top: 65px;
      border-top: 1px solid #edf0f4;
      border-radius: 1px;
      padding-top: 5px;
      color: #8189a1;
      font-family: 'Nexa Book';
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      &-link {
        margin-left: 3.5px;
        color: #365798;
        font-family: 'Nexa Regular';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.1s linear;

        &:hover {
          filter: brightness(1.4);
        }
      }

      &-button {
        display: none;
      }
    }

    &__mobile {
      &-header {
        display: none;
      }

      &-content {
        display: none;
      }

      &-footer {
        display: none;
      }
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    background: url(../../assets/self-sidebar-bg-1920.png);
    background-size: cover;
    background-position: center;
    border-radius: 0 4px 4px 0;
  }

  @media screen and (max-width: 1439px) {
    &__left-section__content {
      margin-top: 35px;
    }

    &__left-section__footer {
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      min-height: 116px;
      height: 116px;
      padding: 15px 25px;
      background: url(../../assets/pricing-header-1920.png);
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px 4px 0 0;

      &-logo {
        font-family: 'Nexa Bold';
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
        filter: invert(2);
        @include userSelectDisable;
      }
    }

    &__left-section {
      width: 100%;
      height: 100%;
      padding: 44px 32px 35px;
      background-color: white;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0px;

      &__header {
        display: none;

        &-title {
          color: #4d4558;
          font-family: 'Nexa XBold';
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;

          span {
            display: block;
            margin-bottom: 6px;
            color: #4d4558;
            font-family: 'Nexa XBold';
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
        }

        &-subtitle {
          margin-top: 35px;
          color: #4d4558;
          font-family: 'Nexa Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      &__content {
        margin-left: 0;
        display: none;

        &-overflow {
          margin-left: 0;
        }

        &__row {
          display: flex;
          flex-direction: row;
          position: relative;

          &-img {
            position: relative;
            padding: 0px 5px;
            cursor: default;
            align-self: baseline;
          }

          &__info {
            display: flex;
            flex-direction: column;
            color: #4d4558;
            height: max-content;

            &-title {
              font-family: 'Nexa Bold';
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }

            &-subtitle {
              margin-top: 3px;
              max-width: 445px;
              font-family: 'Nexa Regular';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: #8189a1;
            }

            &-button {
              display: none;
            }

            &-link {
              display: none;
            }
          }

          &:first-child {
            height: 80px;

            &::after {
              content: '';
              position: absolute;
              display: block;
              height: 60px;
              width: 4px;
              border-radius: 6px;
              background-color: #32b76c;
              transform: translate(21px, 45px);
            }
          }

          &:nth-child(2) {
            margin-top: 34px;
            height: 100px;

            .ss-add-extension__right-section__content__notice__row__info {
              justify-content: flex-start;
            }

            &::after {
              content: '';
              display: block;
              height: 78px;
              width: 1px;
              border-left: 4px dotted #9aabcb;
              position: absolute;
              transform: translate(21px, 44px);
            }
          }

          &:nth-child(3) {
            margin-top: 30px;
            align-items: center;

            &::after {
              content: '';
              position: absolute;
              display: block;
              height: 55px;
              width: 1px;
              background-color: #edf0f4;
              transform: translate(34px, 52px);
            }
          }

          &:last-child {
            &::after {
              content: '';
              display: none;
            }
          }
        }
      }

      &__footer {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: none;

        span {
          display: none;
        }

        &-link {
          display: none;
        }

        &-button {
          display: block;
          color: #ffffff;
          font-family: 'Nexa Bold';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          padding: 6px 12px;
          background-color: #4d4558;
          border-radius: 4px;

          &:hover {
            filter: brightness(1.1);
          }
        }
      }

      &__mobile-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &-title {
          color: #4d4558;
          font-family: 'Nexa XBold';
          font-size: 32px;
          font-weight: 800;
          line-height: 44px;
          letter-spacing: 0px;
          text-align: center;
        }

        &-subtitle {
          margin-top: 40px;
          padding: 0 10px;
          color: #4d4558;
          font-family: 'Nexa Regular';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
        }

        &-icon {
          margin-top: 16px;
          min-width: 36px;
          width: 36px;
          min-height: 36px;
          height: 36px;
        }
      }

      &__mobile-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-title {
          color: #4d4558;
          text-align: center;
          font-family: 'Nexa Bold';
          font-size: 18px;
          font-weight: 700;
          font-style: normal;
          line-height: normal;
        }

        &-subtitle {
          margin-top: 16px;
          color: #8189a1;
          text-align: center;
          font-family: 'Nexa Regular';
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          line-height: normal;
        }
      }

      &__mobile-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-message {
          color: #8189a1;
          text-align: center;
          font-family: 'Nexa Regular';
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          line-height: normal;
        }

        &-button {
          margin-top: 16px;
          display: block;
          color: #ffffff;
          font-family: 'Nexa XBold';
          font-size: 14px;
          font-style: normal;
          font-weight: 800;
          line-height: 20px;
          padding: 6px 12px;
          background-color: #4d4558;
          border-radius: 4px;

          &:hover {
            filter: brightness(1.1);
          }
        }
      }
    }

    &__right-section {
      display: none;
    }
  }
}
