@import './components/PaymentButton/payment-button';

.payment-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  background-color: white;
  border-radius: 12px;
  color: #4d4558;
  height: 100%;
  border-radius: 12px;

  &__left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 75%;
    background-color: #ffffff;
    padding: 60px 30px;
    border-radius: 4px 0px 0px 4px;

    @media screen and (max-width: 1439px) {
      padding: 35px 10px 50px;
    }

    &__link-back {
      position: absolute;
      left: 20px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: 'Nexa Regular';
      font-size: 14px;
      line-height: 21px;
      transition: all 0.2s;
      color: #4d4558;

      &-chevron {
        margin-right: 2px;
        transition: all 0.2s;
      }

      &:hover {
        filter: brightness(1.3);
      }
    }

    &-logo {
      min-width: 94px;
      min-height: 20px;
      width: 94px;
      height: 20px;
    }

    .payment-info {
      padding-left: 48px;

      &-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-family: 'Nexa XBold';
        color: #4d4558;
        font-size: 24px;
        font-weight: 800;
        margin: 20px 0 22px 0;

        div {
          width: 2px;
          height: 20px;
          background-color: #d9d9d9;
          margin: 0px 12px;
        }
      }

      &-field-title {
        font-family: 'Nexa Bold';
        margin: 4px 0;
        color: #4d4558;
        font-size: 12px;
        font-weight: 700;
      }

      &-field-value {
        font-family: 'Nexa Regular';
        color: #8189a1;
        font-size: 12px;
        font-weight: 400;
      }
    }

    form {
      padding-left: 48px;

      .StripeElement {
        width: 500px;
        min-height: 300px;
      }
    }

    .payment-form-footer {
      font-family: 'Nexa Bold';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .payment-form-footer-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 4px 0;
        }

        .trial-info {
          color: #32b76c;
        }
      }

      &__error {
        color: #F0384A;
        margin-bottom: 8px;
        animation: fadeInAnimation 150ms ease-in-out;

        @keyframes fadeInAnimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }

  .payment-footer {
    width: 500px;
    margin-top: 10px;
    margin-left: 50px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;

    .wrapper {
      display: flex;
      justify-content: space-between;
    }

    hr {
      margin: 5px 0;
      cursor: default;
    }

    p {
      cursor: default;

      span {
        font-family: 'Nexa XBold';
        font-size: 11px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: lowercase;
      }
    }

    a {
      color: rgb(0, 0, 0);
      text-decoration: none;
      transition: all 0.1s linear;

      &:hover {
        color: rgb(69, 69, 69);
      }
    }
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 30%;
    width: 30%;
    max-width: 400px;
    background: url(../../assets/self-sidebar-bg-1920.png);
    background-size: cover;
    background-position: center;
    border-radius: 0 4px 4px 0;

    &__slider {
      position: relative;
      text-align: center;
      overflow: hidden;
      height: 310px;
      width: 100%;
      margin: 0 auto;

      &__slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 200px;
        padding: 0 24px;
        opacity: 0;
        animation: slideFadeAnimation 24s ease infinite;
        @include userSelectDisable;

        &-icon {
          margin-top: 20px;
          text-align: right;
          width: 100%;
        }

        &-text {
          font-family: 'Nexa Book';
          font-size: 12px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          color: #ffffff;
        }
      }

      @keyframes slideFadeAnimation {
        0% {
          opacity: 0;
        }

        8%,
        30% {
          opacity: 1;
        }

        40%,
        100% {
          opacity: 0;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-moz-scrollbar {
    width: 6px;
  }

  &::-ms-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-moz-scrollbar-track {
    background: transparent;
  }

  &::-ms-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }

  &::-moz-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }

  &::-ms-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
  }

  &::-moz-scrollbar-thumb:hover {
    background: #dbdbdb;
  }

  &::-ms-scrollbar-thumb:hover {
    background: #dbdbdb;
  }

  .DemoPickerWrapper {
    padding: 0 12px;
    font-family: 'Source Code Pro', monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
  }

  .DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    appearance: none;
  }

  .DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    // padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }

  .p-Input {
    border: 3px solid grey;
  }

  @media screen and (max-width: 900px) {
    &__left-section {
      min-width: 100%;
      max-width: 100%;
      padding: 48px 32px;
      overflow-y: auto;
      overflow-x: hidden;

      &__link-back {
        display: none;
        left: 16px;
        top: 34px;
      }

      &__footer__text {
        padding: 0 20px;
      }

      .payment-info {
        padding-left: 0;

        &-title {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          font-family: 'Nexa XBold';
          color: #4d4558;
          font-size: 24px;
          font-weight: 800;
          margin: 16px 0;

          div {
            display: none;
          }
        }

        &-field-title {
          font-family: 'Nexa Bold';
          margin: 4px 0;
          color: #4d4558;
          font-size: 12px;
          font-weight: 700;
        }

        &-field-value {
          font-family: 'Nexa Regular';
          color: #8189a1;
          font-size: 12px;
          font-weight: 400;
        }
      }

      form {
        padding-left: 0;
        width: 100%;

        .StripeElement {
          width: 100%;
          min-height: 300px;
          margin-top: 40px;
        }
      }

      .payment-form-footer {
        font-family: 'Nexa Bold';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 30px;

        .payment-form-footer-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            p {
              letter-spacing: -0.1px;
            }
          }

          p {
            margin: 4px 0;
          }

          .trial-info {
            color: #32b76c;
          }
        }
      }
    }

    .payment-footer {
      width: 100%;
      margin-top: 50px;
      margin-left: 0;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;

      .wrapper {
        display: flex;
        justify-content: space-between;
      }

      hr {
        margin: 5px 0;
        cursor: default;
      }

      p {
        cursor: default;

        span {
          font-family: 'Nexa XBold';
          font-size: 11px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: lowercase;
        }
      }

      a {
        color: rgb(0, 0, 0);
        text-decoration: none;
        transition: all 0.1s linear;

        &:hover {
          color: rgb(69, 69, 69);
        }
      }
    }

    &__right-section {
      display: none;
    }
  }
}
